import React from "react";
import "./subiecte.css";

import subiecteMatematica from "./Matematica.pdf";
import subiecteEngleza from "./Engleza.pdf";

import Grid from "@material-ui/core/Grid";

const Subiecte = () => {
  return (
    <div className="info">
      <Grid container item xs={12}>
        <h1 className="mainTitle">Modele subiecte</h1>
      </Grid>

      <Grid container xs={12} justify="center">
        <Grid container item xs={12} justify="center">
          <div className="linkContainer">
            <a href={subiecteMatematica} target="_blank">
              Model subiecte Matematica
            </a>
          </div>
        </Grid>

        <Grid xs={12}>
          <p></p>
        </Grid>
        
        <Grid container item xs={12} justify="center">
          <div className="linkContainer">
            <a href={subiecteEngleza} target="_blank">
              Model subiecte Limba Engleza
            </a>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Subiecte;
