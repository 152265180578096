import { Slide } from "@material-ui/core";
import "./home.css";
import Slideshow from "./slideshow/Slideshow";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import Popup from "./Popup";
import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";

import mmConstructii from "./MAIȘTRI ADMINISTRARE PROPRIETATE IMOBILIARĂ.pdf";
import mmIntd from "./MAIȘTRI INTENDENȚĂ.pdf";
import mmIntdANP from "./MAIȘTRI INTENDENȚĂ ANP.pdf";
import mmIntdCNM from "./MAIȘTRI INTENDENȚĂ CNM.pdf";
import sofConstructii from "./SUBOFIȚERI ADMINISTRARE PROPRIETATE IMOBILIARĂ.pdf";
import sofIntd from "./SUBOFIȚERI INTENDENȚĂ.pdf";
import sofAdm from "./SUBOFIȚERI ADMINISTRAȚIE.pdf";

function Home() {
  const [show, setShow] = useState(true); // set to "true" to show popup at page load
  const closePopup = () => {
    setShow(false);
  };

  return (
    <div className="home">
      {/* <div className="rezultate">
      <a href={mmConstructii} target="_blank" className="href">
        Rezultatele obținute de candidați la admitere Maiştri militari /
        ADMINISTRARE PROPRIETATE IMOBILIARA - SESIUNEA II
      </a>
      <br />
      <a href={mmIntd} target="_blank" className="href">
        Rezultatele obținute de candidați la admitere Maiştri Militari /
        INTENDENȚĂ - SESIUNEA II
      </a>{" "}
      <br />
      <a href={mmIntdANP} target="_blank" className="href">
        Rezultatele obținute de candidați la admitere ADMINISTRAȚIA NATIONALA A
        PENITENCIARELOR Maiştri Militari / INTENDENȚĂ - SESIUNEA II
      </a>{" "}
      <br />
      <a href={sofIntd} target="_blank" className="href">
        Rezultatele obținute de candidați la admitere Subofiţeri / INTENDENȚĂ -
        SESIUNEA II
      </a>
      <br />
      <a href={sofConstructii} target="_blank" className="href">
        Rezultatele obținute de candidați la admitere Subofiţeri / ADMINISTRARE
        PROPRIETATE IMOBILIARA - SESIUNEA II
      </a>
      <br />
      <a href={sofAdm} target="_blank" className="href">
        Rezultatele obținute de candidați la admitere Subofiţeri / ADMINISTRAȚIE
        - SESIUNEA II
      </a></div> */}
      <div>
        {/* <i>
          <p className="anunturi-home">
            Vezi{" "}
            <a
              href={
                typeof window !== "undefined"
                  ? `${window.location.origin}/angajare`
                  : ""
              }
              target="_blank"
            >
              {" "}
              AICI
            </a>{" "}
            ultimele anunțuri !
          </p>
        </i> */}
        {/* <p className="examen">
          <p className="subtitlul">
            {" "}
            Școala militară de maiștri militari și subofițeri de logistică
            "Gheorghe Lazăr" organizează concurs de ocupare a următoarelor
            funcții vacante: <br />
          </p>
          🔷 Referent de specialitate gr. III la biroul proiectare,
          implementare, evaluare şi revizuire curriculum educaţional;
          <br />
          🔷 Expert gr. III la Centru de învățare a limbilor străine al
          Comandamentului logistic întrunit;
          <br />
          🔷 Operator gr. III la Centru de editare si multiplicare;
          <br />
          🔷 Operator gr. III la Centru de editare si multiplicare;
          <br />
          🔷 Îngrijitor la grupa administraţie.<br/>

        </p>
        <p className="oferta">
          <i>
            Vezi{" "}
            <a
              href={
                typeof window !== "undefined"
                  ? `${window.location.origin}/anunturi`
                  : ""
              } target="_blank"
            >
              AICI
            </a>{" "}
            mai multe detalii !
          </i>
        </p> */}


        {/* <p>Vezi AICI planul de școlarizare !</p> */}
      </div>
      <Slideshow />
      {/* <div className={show ? "popupWrapper" : "noPopupWrapper"}>
        <Popup showPopup={show} blur={closePopup} />
      </div> */}
      <div className="info-home">
        {/* <div className={show ? "blur" : "noBlur"}></div> */}

        <Grid container xs={12}>
          <Grid item xs={12} md={6}>
            <div className="info-item">
              <MenuBookIcon className="bookIcon" />
              <span>Misiune</span>
              <p>
                Şcoala Militară de Maiştri Militari şi Subofiţeri de Logistică
                „Gheorghe Lazăr”, subordonată Comandamentului logistic întrunit,
                asigură formarea iniţială prin pregătire postliceală a
                maiştrilor militari şi a subofiţerilor de logistică ca
                luptători, lideri ai structurilor de la baza ierarhiei militare
                şi specialişti în asigurarea serviciilor de sprijin logistic,
                exploatarea, repararea, întreţinerea echipamentelor din dotare,
                potrivit nevoilor armatei şi altor beneficiari, atât la pace,
                cât şi la criză şi război, precum şi participarea la formarea
                profesională continuă a personalului de logistică.
              </p>
            </div>
          </Grid>

          <Grid item xs={12} md={6}>
            <div className="info-item">
              <MenuBookIcon className="bookIcon" />
              <span>Viziune</span>
              <p>
                Şcoala Militară de Maiştri Militari şi Subofiţeri de Logistică
                „Gheorghe Lazăr” îşi propune să promoveze o educaţie modernă,
                care să îi ajute pe elevi să devină specialişti militari în
                domeniile funcţionale ale logisticii, să promoveze valorile şi
                tradiţiile poporului român, să fie factori activi în
                consolidarea democraţiei în România.
              </p>
            </div>
          </Grid>
        </Grid>

        {/* <div className="center-wrapper">
                    
                                <div className="info-item">
                                    <MenuBookIcon className="bookIcon"/>
                                    <span>Misiune</span>
                                    <p>Şcoala Militară de Maiştri Militari şi Subofiţeri de Logistică „Gheorghe Lazăr”,
                                    subordonată Comandamentului logistic întrunit, asigură  formarea iniţială 
                                    prin pregătire postliceală a maiştrilor militari şi a subofiţerilor de logistică 
                                    ca luptători, lideri ai structurilor de la baza ierarhiei militare şi specialişti
                                    în asigurarea serviciilor de sprijin logistic, exploatarea, repararea, întreţinerea 
                                    echipamentelor din dotare, potrivit nevoilor armatei şi altor beneficiari, atât la pace,
                                    cât şi la criză şi război, precum şi participarea la formarea profesională continuă a personalului 
                                    de logistică.
                                    </p>
                                </div>
                                <div className="info-item">
                                    <MenuBookIcon  className="bookIcon"/>
                                    <span>Viziune</span>
                                    <p>Şcoala Militară de Maiştri Militari şi Subofiţeri de Logistică „Gheorghe Lazăr” îşi propune să promoveze
                                    o educaţie modernă, care să îi ajute pe elevi să devină specialişti militari în domeniile 
                                    funcţionale ale logisticii, să promoveze valorile şi tradiţiile poporului român, să fie factori 
                                    activi în consolidarea democraţiei în România. 

                                    </p>
                                </div>
                    </div> */}
      </div>
      <p className="homepage-text">
        <i>
          Şcoala Militară de Maiştri Militari şi Subofiţeri de Logistică,
          subordonată Comandamentului logistic întrunit, asigură formarea
          iniţială prin pregătire postliceală a maiştrilor militari şi a
          subofiţerilor de logistică ca luptători, lideri ai structurilor de la
          baza ierarhiei militare şi specialişti în asigurarea serviciilor de
          sprijin logistic, exploatarea, repararea, întreţinerea echipamentelor
          din dotare, potrivit nevoilor armatei şi altor beneficiari, atât la
          pace, cât şi la criză şi război, precum şi participarea la formarea
          profesională continuă a personalului de logistică.
        </i>
      </p>
    </div>
  );
}

export default Home;
