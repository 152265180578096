import React from "react";
import "./conducere.css";
import cdt from "./cdt.jpg";
import sefStatMajor from "./mindricanvalentin.jpg";

function Conducere() {
  return (
    <div>
      <div className="conducere">
        <img src={cdt} alt="comandant" className="image"></img>
        <div className="conducere-info">
          <h2>Comandantul școlii</h2>
          <h5>Colonel doctor Marian - Daniel FĂŞIE</h5>
          <p>
            <strong>Data naşterii:</strong>10 august 1966
          </p>
          <p>
            <strong>Locul naşterii:</strong>Mihail Kogălniceanu, Judeţul
            Constanţa
          </p>
          <p>
            <strong>Naţionalitate:</strong> Română
          </p>
          <strong>Educaţie şi formare:</strong>
          <ul>
            <li>
              Şcoala militară de ofiţeri activi de artilerie antiaeriană şi
              radiolocaţie, specialitatea radiolocaţie, 1987;
            </li>
            <li>
              Curs de specializare pentru ofiţerii de alte arme pe funcţii de
              aprovizionare cu C.L., 1996;
            </li>
            <li>
              Curs de utilizatori calculatoare din compunerea tehnicii de
              radiolocaţie, 1999;
            </li>
            <li>Facultatea de Drept, Universitatea Hyperion, 1999;</li>
            <li>Curs integrator de Stat Major, 2001;</li>
            <li>Curs intensiv de limba franceză, 2001;</li>
            <li>
              Academia de Înalte Ştiinţe Militare - Facultatea interarme,
              specialitatea conducere logistică - 2003;
            </li>
            <li>Curs de limba franceză, Saint Jean, Canada, 2005;</li>
            <li>
              Curs intensiv de învăţare a limbii engleze pentru începători,
              2007;
            </li>
            <li>
              Curs postuniversitar de perfecţionare în specializarea
              Managementul logisticii transporturilor, 2007;
            </li>
            <li>
              Curs postuniversitar de perfecţionare în conducere logistică,
              2009;
            </li>
            <li>
              Curs de specializare pentru ofiţeri în domeniul casării
              muniţiilor, 2009;
            </li>
            <li>Curs de formator (cod COR 241205), 2014;</li>
            <li>
              Programul de studii psihopedagogice, nivel I (iniţial) de
              certificare pentru profesia didactică - monospecializare, 2014;
            </li>
            <li>
              Programul de studii psihopedagogice, nivel II (aprofundare) de
              certificare pentru profesia didactică, 2015;
            </li>
            <li>
              Doctorat în Ştiinţe Militare și Informații, U.N.Ap. "Carol I",
              2016.
            </li>
          </ul>
          <strong>Experienţă profesională:</strong>
          <span>
            <strong>1987 - 1990 </strong>Ofiţer 5 statie radiolocaţie la unitate
            de radiolocaţie;
          </span>
          <span>
            <strong>1990 - 1996 </strong>Şef staţie radioaltimetru, la unitate
            de radiolocaţie;
          </span>
          <span>
            <strong>1996 - 2001 </strong>Ofiţer 3 la locţiitorul comandantului
            pentru Logistică la Centru Radiolocaţie;
          </span>
          <span>
            <strong>2001 - 2003 </strong>Ofiţer student la Academia de Înalte
            Studii Militare;
          </span>
          <span>
            <strong>2003 - 2004 </strong>Şef al operaţiilor şi instrucţiei la
            Brigada 1 Logistică;
          </span>
          <span>
            <strong>2004 - 2005 </strong>Ofiţer 3 în biroul Reglementări în armă
            din secţia Dezvoltare şi reglementări în armă la Şcoala de Aplicaţie
            pentru Logistică "General Constantin Zaharia";
          </span>
          <span>
            <strong>2005 - 2008 </strong>Şef secţie învăţământ la Locţiitorul
            pentru învăţământ la Şcoala de Aplicaţie pentru Logistică "General
            Constantin Zaharia";
          </span>
          <span>
            <strong>2008 - 2012 </strong>Şef al Instrucţiei şi Educaţiei la
            Şcoala de Aplicaţie pentru Logistică "General Constantin Zaharia";
          </span>
          <span>
            <strong>2012 - 2017 </strong>Locţiitor al comandantului la Şcoala de
            Aplicaţie pentru Logistică "General Constantin Zaharia";
          </span>
          <span>
            <strong>2017 - 2020 </strong>(î)Comandant la Şcoala de Aplicaţie
            pentru Logistică "General Constantin Zaharia";
          </span>
          <span>
            <strong>începând cu 2020 </strong>Comandant la Şcoala Militară de
            Maiştri Militari şi Subofiţeri de Logistică„Gheorghe Lazăr”
          </span>
          <strong className="limbi">Limbi străine cunoscute:</strong>
          <ul>
            <li>Franceză</li>
            <li>Engleză</li>
          </ul>
        </div>
      </div>
      <div className="sefStatMajor">
        <img src={sefStatMajor} alt="sef de stat major" className="image"></img>
        <div className="conducere-info">
          <h2>Șef de stat major</h2>
          <h5>Colonel Valentin Mîndrican</h5>
          <p>
            <strong>Data naşterii:</strong>23 octombrie 1972
          </p>
          <p>
            <strong>Locul naşterii:</strong>Găești, Judeţul Dâmbovița
          </p>
          <p>
            <strong>Naţionalitate:</strong> Română
          </p>
          <strong>Educaţie şi formare:</strong>
          <ul>
            <li>
              Institutul militar de intendență și finanțe "Gheorghe Lazăr" Sibiu
              , specialitatea finanțe-contabilitate, 1995;
            </li>
            <li>
              Academia de studii economice, București, curs postuniversitar de
              perfecţionare, specialitatea contabilitate informatizată, 1999;
            </li>
            <li>
              Departamentul pentru pregătirea personalului didactic, nivel 1,
              2011;
            </li>
            <li>
              {" "}
              Departamentul pentru pregătirea personalului didactic, nivel 2,
              2013;
            </li>
            <li>
              Universitatea Națională de Apărare Carol I, Facultatea de Comandă
              și Stat Major, master în științe miliare, 2020
            </li>
          </ul>
          <strong>Experienţă profesională:</strong>
          <span>
            <strong>1995 - 2005 </strong>Ofiţer cu activitatea financiară /
            contabil șef la Școala de Aplicaţie pentru Logistică "General
            Constantin Zaharia";
          </span>
          <span>
            <strong>2005 - 2009 </strong>Ofițer în biroul drepturi bănești
            (salarizare) și decontări la Serviciul Financiar - Contabil al
            Statului Major al Apărării;
          </span>
          <span>
            <strong>2009 - 2021 </strong>Instructor superior / șef catedră
            management financiar - contabil la Școala de Aplicaţie pentru
            Logistică "General Constantin Zaharia"
          </span>
          <span>
            <strong>începând cu 2021 </strong>Șef de stat major la Şcoala
            Militară de Maiştri Militari şi Subofiţeri de Logistică„Gheorghe
            Lazăr”
          </span>
          <strong className="limbi">Limbi străine cunoscute:</strong>
          <ul>
            <li>Engleză</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Conducere;
