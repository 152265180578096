import React from "react";
import Navbar from "react-bootstrap/Navbar";
import PhoneIcon from "@material-ui/icons/Phone";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import {AiFillFacebook} from 'react-icons/ai'
import "./topNavbar.css";

const TopNavbar = () => {
  return (
    <Navbar className="top-navbar">
      <PhoneIcon style={{ fontSize: 20 }} className="phone-icon" />
      <a href="tel:021.436.3484">
        <span className="phone-number">021.436.3484</span>
      </a>
      <span className="separator-line"></span>
      <MailOutlineIcon style={{ fontSize: 20 }} className="mail-icon" />
      <a href="mailto:um02240@mapn.ro">
        <span className="email">um02240@mapn.ro</span>
      </a>
      <span className="separator-line"></span>
      <a href="https://www.facebook.com/SMMMSL" target="_blank">
      <AiFillFacebook style={{ fontSize: 20 }} className="mail-icon" />
      </a>
    </Navbar>
  );
};

export default TopNavbar;
