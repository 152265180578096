import React from "react";
import "./anunturi.css";
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";

import anuntPcc from "./anuntPcc20231103.pdf";

import rezultateANPadm from "./rezultateANPMmAdmProp.pdf";
import rezultateCNM from "./rezultateCNM.pdf";
import rezultateMAIMmAdm from "./rezultateMAIMmAdmProp.pdf";
import rezultateMmAdmProp from "./rezultateMmAdmProp.pdf";
import rezultateMmIntd from "./rezultateMmIntd.pdf";
import rezultateSIEMmAdm from "./rezultateSIEMmAdmProp.pdf";
import rezultateSIEMmIntd from "./rezultateSIEMmIntd.pdf";
import anuntProfesori from "./ang ISMB_2023_11_13_8_58_18_95925.pdf";
import probaScrisa from "./20231205_probaScrisa.pdf";
import interviu from "./interviu_2023.pdf";
import rezultateFinale from "./rezultateFinale_2023.pdf"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: "5px",
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const Anunturi = () => {
  const accordeonClasses = useStyles();

  return (
    <div className="info">
      {/* <Grid container xs={12}>
        <Grid container item xs={12} justify="center">
          <div className="linkContainer1">
            <a className="subTitle" href={anuntRezultateProbaScrisa} target="_blank">
            Rezultatele probei scrise la concursul pentru încadrarea postului de referent de specialitate gr. III la compartimentul gestiune acte de studii şi documente şcolare </a>
          </div>
        </Grid>
      </Grid>
      <Grid container xs={12}>
        <Grid container item xs={12} justify="center">
          <div className="linkContainer1">
            <a className="subTitle" href={anuntRezultateSelectieDosare} target="_blank">
            Rezultatele selecţiei dosarelor de concurs pentru încadrarea postului de referent de specialitate gr. III la compartimentul gestiune acte de studii şi documente şcolare </a>
          </div>
        </Grid>
      </Grid>
      <Grid container xs={12}>
        <Grid container item xs={12} justify="center">
          <div className="linkContainer1">
            <a className="subTitle" href={anuntReferent} target="_blank">
            Anunț referent la compartimentul gestiune acte de studii și documente școlare
            </a>
          </div>
        </Grid>
      </Grid>
      <Grid container xs={12}>
        <Grid container item xs={12} justify="center">
          <div className="linkContainer1">
            <a className="subTitle" href={anuntPostDidactic} target="_blank">
              Anunt organizare concurs post didactic
            </a>
          </div>
        </Grid>
      </Grid>
      <Grid container xs={12}>
        <Grid container item xs={12} justify="center">
          <div className="linkContainer1">
            <a className="subTitle" href={anunt11102022} target="_blank">
              Anunt activitatea de evaluare a personalului didactic în vederea acordării avizului MApN
            </a>
          </div>
        </Grid>
      </Grid> */}
      {/* <Grid container xs={12}>
        <Grid container item xs={12} justify="center">
          <div className="linkContainer1">
            <a className="subTitle" href={rezultateMmIntd} target="_blank">
              Rezultate obținute de candidați la concursul de admitere MAIȘTRI
              MILITARI/INTENDENȚĂ - 09.08.2023
            </a>
          </div>
        </Grid>
      </Grid>
      <Grid container xs={12}>
        <Grid container item xs={12} justify="center">
          <div className="linkContainer1">
            <a className="subTitle" href={rezultateMmAdmProp} target="_blank">
              Rezultate obținute de candidați la concursul de admitere
              MAIȘTRI
              MILITARI/ADMINISTRARE PROPRIETATE IMOBILIARA - 09.08.2023
            </a>
          </div>
        </Grid>
      </Grid>
      <Grid container xs={12}>
        <Grid container item xs={12} justify="center">
          <div className="linkContainer1">
            <a className="subTitle" href={rezultateCNM} target="_blank">
              Rezultate obținute de candidați pe locurile Colegiilor Militare Naționale - 09.08.2023
            </a>
          </div>
        </Grid>
      </Grid>
      <Grid container xs={12}>
        <Grid container item xs={12} justify="center">
          <div className="linkContainer1">
            <a className="subTitle" href={rezultateMAIMmAdm} target="_blank">
              Rezultate obținute de candidați la concursul de admitere Ministerul Afacerilor Interne MAIȘTRI
              MILITARI/ADMINISTRARE PROPRIETATE IMOBILIARA - 09.08.2023
            </a>
          </div>
        </Grid>
      </Grid>
      <Grid container xs={12}>
        <Grid container item xs={12} justify="center">
          <div className="linkContainer1">
            <a className="subTitle" href={rezultateANPadm} target="_blank">
              Rezultate obținute de candidați la concursul de admitere Agenția Naționala a Penitenciarelor MAIȘTRI
              MILITARI/ADMINISTRARE PROPRIETATE IMOBILIARA - 09.08.2023
            </a>
          </div>
        </Grid>
      </Grid>
      <Grid container xs={12}>
        <Grid container item xs={12} justify="center">
          <div className="linkContainer1">
            <a className="subTitle" href={rezultateSIEMmAdm} target="_blank">
              Rezultate obținute de candidați la concursul de admitere SERVICIUL DE INFORMAȚII EXTERNE
              MAIȘTRI
              MILITARI/ADMINISTRARE PROPRIETATE IMOBILIARA - 09.08.2023
            </a>
          </div>
        </Grid>
      </Grid>
      <Grid container xs={12}>
        <Grid container item xs={12} justify="center">
          <div className="linkContainer1">
            <a className="subTitle" href={rezultateSIEMmIntd} target="_blank">
              Rezultate obținute de candidați la concursul de admitere
              SERVICIUL DE INFORMAȚII EXTERNE
              MAIȘTRI MILITARI/INTENDENȚĂ - 09.08.2023
            </a>
          </div>
        </Grid>
      </Grid> */}
      <Grid container xs={12}>
        <Grid container item xs={12} justify="center">
          <div className="linkContainer1">
            <a className="subTitle" href={rezultateFinale} target="_blank">
              Rezultatele finale la concursurile de ocupare a funcțiilor vacante
            </a>
          </div>
        </Grid>
      </Grid>
      <Grid container xs={12}>
        <Grid container item xs={12} justify="center">
          <div className="linkContainer1">
            <a className="subTitle" href={interviu} target="_blank">
              Rezultatele interviurilor la concursurile de ocupare a funcțiilor vacante
            </a>
          </div>
        </Grid>
      </Grid>
      <Grid container xs={12}>
        <Grid container item xs={12} justify="center">
          <div className="linkContainer1">
            <a className="subTitle" href={probaScrisa} target="_blank">
              Rezultatele probei scrise la concursurile de ocupare a funcțiilor vacante
            </a>
          </div>
        </Grid>
      </Grid>
      <Grid container xs={12}>
        <Grid container item xs={12} justify="center">
          <div className="linkContainer1">
            <a className="subTitle" href={anuntProfesori} target="_blank">
              Anunț organizare concurs posturi didactice
            </a>
          </div>
        </Grid>
      </Grid>
      <Grid container xs={12}>
        <Grid container item xs={12} justify="center">
          <div className="linkContainer1">
            <a className="subTitle" href={anuntPcc} target="_blank">
              Anunț ocupare funcții vacante în cadrul SMMMSL
            </a>
          </div>
        </Grid>
      </Grid>
    </div>
    //             <div className="info">

    //               <Grid container xs={12}>

    //       {/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
    //           {/* Asta se copie pt anunt nou */}

    //           <Grid container item xs={12} justify="center">
    //                        <div className="linkContainer1">
    //                             <a className="subTitle" href={anunt20211220} target="_blank">
    //                             Anunț cu rezultatele selecţiei dosarelor de concurs pentru încadrarea postului de
    // Referent de specialitate gr. III la biroul management şi asigurarea calităţii educaţiei
    //                             </a>
    //                        </div>
    //                 </Grid>

    //                 <Grid xs={12}>
    //                        <p></p>
    //                    </Grid>

    //    {/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}

    //                 <Grid container item xs={12} justify="center">
    //                        <div className="linkContainer1">
    //                             <a className="subTitle" href={anunt14122021} target="_blank">
    //                             Anunț organizare consurs pentru ocuparea a două posturi didactice
    //                             </a>
    //                        </div>
    //                 </Grid>

    //                 <Grid xs={12}>
    //                        <p></p>
    //                    </Grid>

    //               <Grid container item xs={12} justify="center">
    //                        <div className="linkContainer1">
    //                             <a className="subTitle" href={anunt03122021} target="_blank">
    //                             Anunț post referent de specialitate gr. III la biroul management şi asigurarea calităţii educaţiei
    //                             </a>
    //                        </div>
    //                 </Grid>

    //                 <Grid xs={12}>
    //                        <p></p>
    //                    </Grid>

    //               <Grid container item xs={12} justify="center">
    //                        <div className="linkContainer1">
    //                           <a className="subTitle" href={rezultateFinale2} target="_blank">
    //                           REZULTATELE FINALE</a>
    //                                                   </div>
    //                 </Grid>

    //                 <Grid xs={12}>
    //                        <p></p>
    //                    </Grid>

    //               <Grid container item xs={12} justify="center">
    //                        <div className="linkContainer1">
    //                           <a className="subTitle" href={rezultateIngrijitor2} target="_blank">
    //                           Rezultatele probei practice la concursul organizat pentru încadrarea postului de Îngrijitor debutant la grupa administraţie                          </a>
    //                         </div>
    //                 </Grid>

    //                 <Grid xs={12}>
    //                        <p></p>
    //                    </Grid>

    //               <Grid container item xs={12} justify="center">
    //                        <div className="linkContainer1">
    //                           <a className="subTitle" href={rezultateSalar} target="_blank">
    //                           Rezultatele probei scrise la concursul organizat pentru încadrarea postului de Contabil II la compartimentul salarizare şi decontări
    //                           </a>
    //                         </div>
    //                 </Grid>

    //                 <Grid xs={12}>
    //                        <p></p>
    //                    </Grid>

    //               <Grid container item xs={12} justify="center">
    //                        <div className="linkContainer1">
    //                           <a className="subTitle" href={rezultateContabil} target="_blank">
    //                           Rezultatele selecţiei dosarelor de concurs pentru încadrarea postului de Contabil II la compartimentul salarizare şi decontări, cu o vechime în specialitatea studiilor de 6 luni    </a>                    </div>

    //                 </Grid>

    //                 <Grid xs={12}>
    //                        <p></p>
    //                    </Grid>

    //               <Grid container item xs={12} justify="center">
    //                        <div className="linkContainer1">
    //                           <a className="subTitle" href={rezultateActe} target="_blank">
    //                           Rezultatele probei scrise la concursul organizat pentru încadrarea postului de Referent de specialitate gr. III la compartimentul gestiune acte de studii şi documente şcolare                      </a>
    //                         </div>

    //                 </Grid>

    //                 <Grid xs={12}>
    //                        <p></p>
    //                    </Grid>

    //               <Grid container item xs={12} justify="center">
    //                        <div className="linkContainer1">
    //                           <a className="subTitle" href={rezultateScrisIngrijitor} target="_blank">
    //                           Rezultatele probei scrise la concursul organizat pentru încadrarea postului de Îngrijitor debutant la grupa administraţie
    //                       </a>
    //                         </div>

    //                 </Grid>

    //                 <Grid xs={12}>
    //                        <p></p>
    //                    </Grid>

    //               <Grid container item xs={12} justify="center">
    //                        <div className="linkContainer1">
    //                           <a className="subTitle" href={rezultateReferentCuriculum} target="_blank">
    //                           Rezultatele probei scrise la concursul organizat pentru încadrarea postului de Referent de specialitate gr. III la biroul proiectare, implementare, evaluare şi revizuire curriculum educaţional                          </a>
    //                            </div>

    //                 </Grid>

    //                 <Grid xs={12}>
    //                        <p></p>
    //                    </Grid>

    //               <Grid container item xs={12} justify="center">
    //                        <div className="linkContainer1">
    //                           <a className="subTitle" href={rezultateReferent} target="_blank">
    //                           Rezultatele selecţiei dosarelor de concurs pentru încadrarea postului de Referent de specialitate gr. III la compartimentul gestiune acte de studii şi documente şcolare;
    //                           </a>
    //                            </div>

    //                 </Grid>

    //                 <Grid xs={12}>
    //                        <p></p>
    //                    </Grid>

    //               <Grid container item xs={12} justify="center">
    //                        <div className="linkContainer1">
    //                           <a className="subTitle" href={rezultateIngrijitor1} target="_blank">
    //                             Rezultatele selecţiei dosarelor de concurs pentru încadrarea postului de Îngrijitor debutant la grupa administraţie                            </a>
    //                         </div>
    //                 </Grid>

    //                 <Grid xs={12}>
    //                        <p></p>
    //                    </Grid>

    //               <Grid container item xs={12} justify="center">
    //                        <div className="linkContainer1">
    //                           <a className="subTitle" href={anunt3} target="_blank">Rezultatele probei scrise la concursul organizat pentru încadrarea postului de Asistent medical la Cabinetul medical (cu staţionar)</a>
    //                         </div>
    //                 </Grid>

    //                 <Grid xs={12}>
    //                        <p></p>
    //                    </Grid>

    //               <Grid container item xs={12} justify="center">
    //                        <div className="linkContainer1">
    //                           <a className="subTitle" href={anunt1} target="_blank">Rezultatele selecţiei dosarelor de concurs pentru încadrarea postului de Referent de specialitate gr. III la biroul proiectare, implementare, evaluare şi revizuire curriculum educaţional</a>
    //                         </div>
    //                 </Grid>

    //                 <Grid xs={12}>
    //                        <p></p>
    //                    </Grid>

    //               <Grid container item xs={12} justify="center">
    //                        <div className="linkContainer1">
    //                           <a className="subTitle" href={rezultateAsistent} target="_blank">Rezultatele selecţiei dosarelor de concurs pentru încadrarea postului de Asistent medical la Cabinetul medical</a>
    //                         </div>
    //                 </Grid>

    //                 <Grid xs={12}>
    //                        <p></p>
    //                    </Grid>

    //                 <Grid container item xs={12} justify="center">
    //                        <div className="linkContainer1">
    //                           <a className="subTitle" href={ingrijitor} target="_blank">Anunt ingrijitor</a>
    //                         </div>
    //                 </Grid>

    //                 <Grid xs={12}>
    //                        <p></p>
    //                    </Grid>

    //                 <Grid container item xs={12} justify="center">
    //                 <div className="linkContainer1">
    //                 <a className="subTitle" href={contabil} target="_blank">Anunt contabil</a>
    //                 </div>
    //                 </Grid>

    //                 <Grid xs={12}>
    //                        <p></p>
    //                    </Grid>

    //                 <Grid container item xs={12} justify="center">
    //                 <div className="linkContainer1">
    //                 <a className="subTitle" href={asistent} target="_blank">Anunt asistent medical</a>
    //                 </div>
    //                 </Grid>

    //                 <Grid xs={12}>
    //                        <p></p>
    //                    </Grid>

    //                 <Grid container item xs={12} justify="center">
    //                 <div className="linkContainer1">
    //                 <a className="subTitle" href={referent} target="_blank">Anunt referent la compartimentul gestiune acte de studii si documente scolare</a>
    //                 </div>
    //                 </Grid>

    //                 <Grid xs={12}>
    //                        <p></p>
    //                    </Grid>

    //                 <Grid container item xs={12} justify="center">
    //                 <div className="linkContainer1">
    //                 <a className="subTitle" href={referentPier} target="_blank">Anunt referent birou P.I.E.R. curriculum educational</a>
    //                 </div>
    //                 </Grid>

    //               </Grid>

    // </div>
  );
};

export default Anunturi;
