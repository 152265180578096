import React from "react";
import "./popup.css";
import metodologie from "./metodologie.PDF";
import rezultate from "./rezultateFinale.PDF";
// import rezultate2 from "../admitere/informatiiAdmitere/rezultate2.PDF";
import anexaMetodologie from "../admitere/informatiiAdmitere/anexaMetodologie.PDF";
import rezultateAsistent from "./rezultateAsistent.pdf";
import locuriNeocupate from "./locuriNeocupate.PDF";
import anuntPostDidactic from "./anuntPostDidactic.pdf";
import locuriDisponibile from "./locuriDisponibile.pdf";
import locuriDisponibileMM from "./locuriDispnobileMM.pdf";

function Popup(props) {
  const handleClickAici = () => {
    props.blur();
  };

  return (
    <div className={props.showPopup ? "popup" : "hidePopup"}>
      <button className="exit-button" onClick={props.blur}>
        X
      </button>
      <div className="textWrapper">
        {/* <span className="top-text"><strong><h5>ANUNȚ ORGANIZARE CONCURS POST DIDACTIC</h5></strong> </span>
            <a href={anuntPostDidactic} target="_blank" className="bottom-text" onClick={handleClickAici}>Deschide</a> */}
        <p>
          {" "}
          Vezi{" "}
          <span>
            {" "}
            <strong>
              <a
                className="bottom-text"
                href={
                  typeof window !== "undefined"
                    ? `${window.location.origin}/anunturi`
                    : ""
                }
              >
                {" "}
                AICI{" "}
              </a>
            </strong>
          </span>{" "}
          ultimele rezultate la concursurile de ocupare a funcțiilor
          vacante
        </p>
      </div>
    </div> 
  );
}

export default Popup;
