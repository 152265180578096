import React from "react";

import "./infoAdmitere.css";
// import metodologie from "./metodologie.PDF";
// import rezultateInt from "./rezultateIntendenta.pdf";
// import rezultateAdm from "./rezultateAdministratie.pdf";
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";

//pdf-uri corecte

import ofertaScolarizare from "./planScolarizare2023.pdf";
import anuntLocuriSIE from "./anuntLocuriSIE.pdf";
import anuntLocuriSRI from "./anuntLocuriSRI.pdf";
import metodologie2023 from "./metodologie2023.pdf";

import locuriDisponibile from "./locuriDisponibile.pdf";
import rezultateSofAdm from "./rezultateSofAdm.pdf";
import rezultateSofIntd from "./rezultateSofIntd.pdf";
import rezultateSofAdmPropImob from "./rezultateSofAdmPropImob.pdf";
import rezultateSRI from "./rezultateSRI.pdf";
import rezultateSieIntd from "./rezultateSieIntd.pdf";
import rezultateSieAdmPropImob from "./rezultateSieAdmPropImob.pdf";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: "5px",
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const InfoAdmitere = () => {
  const accordeonClasses = useStyles();

  return (
    <div className="viziune">
      <h1>
        METODOLOGIE-CADRU din 29 mai 2024
        <br /> privind organizarea și desfășurarea admiterii în școlile militare
        de maiștri militari și subofițeri
      </h1>
      <p className="viziune-text">
        Prezenta metodologie-cadru asigură reglementarea admiterii la programele
        de studii postliceale în unitățile de învățământ postliceal militar,
        denumite în continuare școli militare de maiștri militari și subofițeri,
        începând cu anul școlar 2024-2025.
      </p>
      <p className="viziune-text">
        Metodologia stabilește procedura de admitere a candidaților în școlile
        militare de maiștri militari și subofițeri, pentru locurile alocate atât
        Ministerului Apărării Naționale, cât și celorlalte structuri din cadrul
        sistemului de apărare, ordine publică și securitate națională, prin
        Planul de școlarizare al Ministerului Apărării Naționale, secțiunea
        „Învățământ pentru formarea maiștrilor militari și subofițerilor prin
        studii postliceale“, denumit în continuare plan de școlarizare.
      </p>
      <p className="viziune-text">
        Fac obiectul admiterii candidații declarați „Admis/Apt“ la probele de
        selecție și, după caz, declarați „Admis/Apt“ la examinarea medicală
        specifică și/sau evaluarea psihologică a aptitudinilor speciale, în
        cazul celor care candidează pentru arme și specialități militare care
        necesită acest tip de evaluare/examinare.
      </p>
      <p className="viziune-text">
        (1) Admiterea se organizează și se desfășoară centralizat, în cadrul
        Școlii Militare de Maiștri Militari și Subofițeri a Forțelor Terestre
        „Basarab I“, pentru toate școlile militare de maiștri militari și
        subofițeri din Ministerul Apărării Naționale, potrivit graficului
        activităților aprobat prin dispoziție anuală a șefului Direcției
        generale management resurse umane și în condițiile prezentei
        metodologii. <br />
        (2) Admiterea se organizează pentru următoarele tipuri de programe:{" "}
        <br />
        a) studii postliceale pentru formarea inițială a maiștrilor militari în
        activitate; <br />
        b) studii postliceale pentru formarea inițială a subofițerilor în
        activitate. <br />
        (3) Admiterea la programele de studii postliceale menționate la alin.
        (2) se organizează și se desfășoară în cel mult două sesiuni de
        admitere, la datele specificate în graficul prevăzut la alin. (1).{" "}
        <br />
        (4) Graficul activităților prevăzut la alin. (1) se elaborează în
        concordanță cu calendarul național al examenului național de
        bacalaureat, cu graficul anual de desfășurare a activităților de
        recrutare și selecție a candidaților pentru învățământul postliceal
        militar și cu datele finalizării admiterii la programele de studii
        universitare de licență în instituțiile de învățământ superior militar
        și se aprobă în termen de 30 de zile calendaristice de la publicarea
        planului de școlarizare pentru următorul an de învățământ.
      </p>
      <p className="viziune-text">
        Admiterea se realizează în baza mediei obținute la examenul național de
        bacalaureat, în funcție de opțiunile exprimate în cererea de înscriere
        și de rezultatele selecției, în limita numărului de locuri aprobate
        pentru fiecare armă sau serviciu și specialitate militară, atât pentru
        Ministerul Apărării Naționale, cât și pentru celelalte structuri din
        cadrul sistemului de apărare, ordine publică și securitate națională.
      </p>
      <p className="viziune-text">
        (1) Copiii personalului, așa cum este definit la art. 16 alin. (1) lit.
        a) și art. 18 alin. (1) lit. b) din Legea nr. 168/2020 pentru
        recunoașterea meritelor personalului participant la acțiuni militare,
        misiuni și operații pe teritoriul sau în afara teritoriului statului
        român și acordarea unor drepturi acestuia, familiei acestuia și
        urmașilor celui decedat, cu modificările și completările ulterioare, și
        la art. 25 alin. 2 din Legea nr. 80/1995 privind statutul cadrelor
        militare, cu modificările și completările ulterioare, dobândesc
        calitatea de elev în școlile militare de maiștri militari și subofițeri,
        dacă îndeplinesc criteriile de recrutare și au fost declarați
        „Admis/Apt“ la probele de selecție, în baza dosarului de candidat
        prevăzut la art. 6 alin. (1) din Instrucțiunile privind organizarea și
        funcționarea sistemului de recrutare și selecție a candidaților în
        vederea admiterii la studii/cursuri de formare a cadrelor militare în
        activitate, respectiv pentru dobândirea calității de soldat/ gradat
        profesionist în structurile Ministerului Apărării Naționale, aprobate
        prin Ordinul ministrului apărării naționale nr. M.217/2019, cu
        modificările și completările ulterioare. <br />
        (2) Numărul locurilor scoase la concurs reprezintă numărul locurilor din
        cifra de școlarizare, din care se reduce numărul locurilor ocupate de
        către candidații menționați la alin. (1).
      </p>
      <p className="viziune-text">
        Numărul de locuri aprobate prin planul de școlarizare se actualizează la
        finalizarea repartiției absolvenților colegiilor naționale militare,
        potrivit prevederilor art. 5 alin. (5) din Metodologia privind procedura
        de repartiție a absolvenților colegiilor naționale militare în
        instituțiile de învățământ superior militar și calculul mediei de
        repartiție, aprobată prin Ordinul ministrului apărării naționale nr.
        M.258/2023.
      </p>
      <p className="viziune-text">
        (1) Admiterea candidaților se realizează computerizat, prin intermediul
        Platformei de înscriere online care funcționează pe website-ul destinat
        informării și recrutării candidaților la profesia militară
        (https://www.recrutaremapn.ro), denumită în continuare Platformă. <br />
        (2) Candidații sunt ierarhizați în ordinea strict descrescătoare a
        mediilor obținute la examenul național de bacalaureat și repartizați
        potrivit opțiunilor acestora, exprimate în cererea de înscriere. <br />
        (3) Departajarea candidaților ierarhizați, care au medii de admitere
        egale, se realizează aplicând, în ordine, următoarele criterii: a) nota
        obținută la proba scrisă la Limba și literatura română din cadrul
        examenului național de bacalaureat; b) nota obținută la proba scrisă
        obligatorie a profilului din cadrul examenului național de bacalaureat;
        c) nota obținută la proba scrisă la alegere a profilului și a
        specializării din cadrul examenului național de bacalaureat. <br />
        (4) Admiterea candidaților înscriși pe locurile alocate celorlalte
        structuri din cadrul sistemului de apărare, ordine publică și securitate
        națională nu face obiectul prevederilor alin. (1).
        <br />
        (5) Pentru candidații menționați la alin. (4), admiterea se face de
        către comisia de admitere menționată la art. 10 lit. c), cu respectarea
        prevederilor alin. (2) și (3). <br />
        (6) Dosarele candidaților menționați la art. 2 se centralizează la
        Școala Militară de Maiștri Militari și Subofițeri a Forțelor Terestre
        „Basarab I“.
      </p>
      <p className="viziune-text">
        (1) Pentru maximizarea șanselor de admitere, candidații pot să își
        exprime toate opțiunile pentru admiterea în școlile militare de maiștri
        militari și subofițeri, corespunzătoare numărului de locuri rezervat
        acestora prin planul de școlarizare, mai puțin pentru armele sau
        serviciile și specialitățile militare unde aceștia nu au fost declarați
        „Admis/Apt“ la examinarea medicală specifică și/sau la evaluarea
        psihologică a aptitudinilor speciale. <br />
        (2) Candidații care îndeplinesc condiția prevăzută la art. 3, dar nu se
        încadrează, în urma ierarhizării, în numărul de locuri alocate
        Ministerului Apărării Naționale și celorlalte structuri din cadrul
        sistemului de apărare, ordine publică și securitate națională sunt
        declarați „Neadmis“.
      </p>
      <p className="viziune-text">
        Pentru organizarea și desfășurarea admiterii se constituie următoarele
        comisii: <br />
        a) comisia de verificare a dosarelor candidaților; <br />
        b) comisia de soluționare a contestațiilor; <br />
        c) comisia de admitere.
      </p>
      <p className="viziune-text">
        (1) Componența nominală a comisiilor prevăzute la art. 10 lit. a) și b)
        se stabilește de către comandantul Școlii Militare de Maiștri Militari
        și Subofițeri a Forțelor Terestre „Basarab I“ cu cel puțin 30 de zile
        calendaristice înainte de data ierarhizării candidaților, pentru prima
        sesiune, respectiv cu 12 zile calendaristice pentru a doua sesiune, se
        înscrie în ordinul de zi pe unitate al școlii, la datele specificate în
        graficul menționat la art. 4 alin. (1), și se comunică la Direcția
        generală management resurse umane, prin grija șefului structurii
        Instrucție și educație. <br />
        (2) Comandantul Școlii Militare de Maiștri Militari și Subofițeri a
        Forțelor Terestre „Basarab I“ dispune măsurile necesare în vederea
        creării conturilor de utilizatori pe Platformă a membrilor desemnați să
        facă parte din comisia de verificare a dosarelor candidaților și le
        înaintează în vederea aprobării de către directorul Direcției generale
        management resurse umane. <br />
        (3) Componența nominală a comisiei prevăzute la art. 10 lit. c) se
        stabilește de către șeful Direcției generale management resurse umane,
        cu cel puțin 5 zile calendaristice înainte de data ierarhizării
        candidaților, pentru ambele sesiuni de admitere, și se înscrie în
        ordinul de zi pe unitate al direcției generale, la datele specificate în
        graficul prevăzut la art. 4 alin. (1), prin grija șefului Serviciului
        strategii, politici și reglementări în domeniul educației militare.
        <br /> (4) Nu pot face parte din comisiile prevăzute la art. 10
        persoanele care au rude sau afini până la gradul IV în rândul
        candidaților.
      </p>
      <p className="viziune-text">
        (1) Comisia de verificare a dosarelor candidaților se constituie din:{" "}
        <br />
        a) președinte: locțiitorul comandantului Școlii Militare de Maiștri
        Militari și Subofițeri a Forțelor Terestre „Basarab I“ sau înlocuitorul
        legal; <br />
        b) cinci grupuri de lucru cu minimum patru membri, astfel: două cadre
        didactice/instructori militari/personal didactic auxiliar și/sau cadre
        militare, un subofițer de stat major pentru activități logistice și
        gestionarea documentelor și un analist programator/ inginer de
        sistem/utilizator de mijloace informatice, din fiecare școală militară
        de maiștri militari și subofițeri, desemnați de către fiecare comandant
        al școlii militare de maiștri militari și subofițeri. <br />
        (2) Secretariatul comisiei prevăzute la alin. (1) este asigurat de către
        un ofițer cu funcție de conducere din cadrul structurii Instrucție și
        educație/similar din una din cele cinci școli militare de maiștri
        militari și subofițeri, desemnat de comandantul școlii militare de
        maiștri militari și subofițeri.
      </p>
      <p className="viziune-text">
        (1) Comisia de soluționare a contestațiilor se constituie din personalul
        celor cinci școli militare de maiștri militari și subofițeri, astfel:{" "}
        <br />
        a) președinte: comandantul Școlii Militare de Maiștri Militari și
        Subofițeri a Forțelor Terestre „Basarab I“; <br />
        b) membri: câte un ofițer cu funcție de conducere din cadrul structurii
        Instrucție și educație/similar din fiecare școală. <br />
        (2) Secretariatul comisiei prevăzute la alin. (1) este asigurat de către
        șeful structurii Instrucție și educație/similar din una dintre școlile
        militare de maiștri militari și subofițeri, alta decât Școala Militară
        de Maiștri Militari și Subofițeri a Forțelor Terestre „Basarab I“.
      </p>
      <p className="viziune-text">
        (1) Comisia de admitere se constituie din:
        <br /> a) președinte: directorul Direcției generale management resurse
        umane sau înlocuitorul legal; <br />
        b) membri: șeful Biroului învățământ preuniversitar din cadrul
        Serviciului strategii, politici și reglementări în domeniul educației
        militare din Direcția generală management resurse umane, șeful Biroului
        învățământ preuniversitar din cadrul Serviciului învățământ și educație
        din Direcția instruire și doctrină din Statul Major al Apărării,
        locțiitorii comandanților celorlalte patru școli militare de maiștri
        militari și subofițeri, cu excepția locțiitorului comandantului Școlii
        Militare de Maiștri Militari și Subofițeri a Forțelor Terestre „Basarab
        I“. <br />
        (2) Secretariatul comisiei prevăzute la alin. (1) este asigurat de către
        șeful structurii Instrucție și educație din Școala Militară de Maiștri
        Militari și Subofițeri a Forțelor Terestre „Basarab I“. <br />
        (3) Președintele comisiei de admitere dispune măsurile necesare în
        vederea creării conturilor de utilizatori pe Platformă ale membrilor
        comisiei, pe care le supune aprobării locțiitorului șefului Direcției
        generale management resurse umane.
      </p>
      <p className="viziune-text">
        Președinții comisiilor au următoarele atribuții generale: <br />
        a) instruiesc membrii comisiilor cu privire la sarcinile ce le revin,
        inclusiv la cele referitoare la utilizarea Platformei, prevederile
        legale, procedurile și regulile pe care trebuie să le respecte și le
        aduc la cunoștință măsurile care se pot aplica în cazul nerespectării
        acestora; <br />
        b) dispun încetarea imediată a exercitării atribuțiilor membrilor pentru
        care se constată abateri de la respectarea instructajului efectuat și
        iau măsuri pentru consemnarea abaterii în procesul-verbal al admiterii;{" "}
        <br />
        c) interzic accesul altor persoane în spațiile destinate activității
        comisiilor.
      </p>
      <p className="viziune-text">
        Comisia de verificare a dosarelor candidaților are următoarele
        responsabilități: <br />
        a) verifică și actualizează datele candidaților din Platformă; <br />
        b) introduce în Platformă rezultatele obținute de către candidați la
        examenul național de bacalaureat; <br />
        c) verifică dosarele de candidat potrivit graficului prevăzut la art. 4
        alin. (1); <br />
        d) comunică permanent cu birourile informare-recrutare și cu celelalte
        structuri din cadrul sistemului de apărare, ordine publică și securitate
        națională privind problemele identificate în dosarele candidaților și ia
        măsuri pentru rezolvarea acestora; <br />
        e) întocmește și transmite comisiei de admitere procesul-verbal la
        finalizarea activității.
      </p>
      <p className="viziune-text">
        (1) Comisia de soluționare a contestațiilor are următoarele
        responsabilități: <br />
        a) ia în evidență contestațiile; <br />
        b) analizează și soluționează contestațiile; <br />
        c) verifică corectitudinea înscrierii mediilor obținute la examenul
        național de bacalaureat prevăzute la art. 8 alin. (2) și (3); <br />
        d) încheie procesul-verbal cu rezultatul soluționării contestațiilor și
        îl transmite președintelui comisiei de admitere; <br />
        e) comunică rezultatul contestațiilor candidaților, potrivit graficului
        menționat la art. 4 alin. (1). <br />
        (2) Deciziile comisiei de soluționare a contestațiilor sunt definitive.
      </p>
      <p className="viziune-text">
        Comisia de admitere are următoarele responsabilități: <br />
        a) ierarhizează candidații înscriși pe locurile alocate Ministerului
        Apărării Naționale, în ordinea descrescătoare a mediei obținute la
        examenul național de bacalaureat, și îi repartizează potrivit opțiunilor
        acestora, utilizând Platforma prevăzută la art. 8 alin. (1); <br />
        b) ierarhizează candidații înscriși pe locurile alocate celorlalte
        structuri din cadrul sistemului de apărare, ordine publică și securitate
        națională, în ordinea descrescătoare a mediei obținute la examenul
        național de bacalaureat, și îi repartizează potrivit opțiunilor
        acestora; <br />
        c) încheie procesul-verbal cu admiterea candidaților; <br />
        d) întocmește și transmite, în termen de 2 zile lucrătoare de la
        finalizarea admiterii, pentru ambele sesiuni de admitere, extrasele din
        situația centralizatoare cu admiterea candidaților și dosarele acestora
        la școlile militare de maiștri militari și subofițeri, precum și tabelul
        nominal cu rezultatele candidaților declarați „Admis“/“Neadmis“ în
        școlile militare de maiștri militari și subofițeri.
      </p>
      <p className="viziune-text">
        (1) Înscrierea datelor de contact, a opțiunilor și a reorientării
        candidaților în vederea admiterii în școlile militare de maiștri
        militari și subofițeri se poate face de către candidați, individual,
        prin intermediul Platformei sau de către birourile informare-recrutare.
        <br />
        (2) Pentru introducerea în Platformă de către comisia de verificare a
        dosarelor candidaților a rezultatelor obținute la examenul național de
        bacalaureat, candidatul prezintă la birourile informare-recrutare copia
        diplomei de bacalaureat, împreună cu originalul sau adeverința care
        atestă promovarea de către acesta a examenului național de bacalaureat,
        conform prevederilor legale în vigoare, din care să reiasă media
        generală și notele obținute la disciplinele de examen.
      </p>
      <p className="viziune-text">
        (1) Școlile militare de maiștri militari și subofițeri afișează la
        sediul acestora situația centralizatoare cu datele primite, conform
        celor specificate la art. 18 lit. d). <br />
        (2) Tabelul nominal cu rezultatele candidaților declarați
        „Admis“/„Neadmis“ în școlile militare de maiștri militari și subofițeri
        se publică, prin afișarea la loc vizibil, la sediul școlilor militare de
        maiștri militari și subofițeri și prin postarea pe site-ul acestora, în
        conformitate cu prevederile Regulamentului (UE) nr. 679/2016 al
        Parlamentului European și al Consiliului din 27 aprilie 2016 privind
        protecția persoanelor fizice în ceea ce privește prelucrarea datelor cu
        caracter personal și privind libera circulație a acestor date și de
        abrogare a Directivei 95/46/CE (Regulamentul general privind protecția
        datelor).
      </p>
      <p className="viziune-text">
        (1) Candidații care sunt declarați „Admis“ în școlile militare de
        maiștri militari și subofițeri confirmă locul ocupat, prin validare în
        Platformă, potrivit prevederilor dispoziției anuale a șefului Direcției
        generale management resurse umane, menționată la art. 4 alin. (1).
        <br /> (2) Prin excepție de la prevederile alin. (1), candidații
        declarați „Admis“ pe locurile alocate celorlalte structuri din cadrul
        sistemului de apărare, ordine publică și securitate națională confirmă
        locul ocupat la școala militară de maiștri militari și subofițeri unde
        au fost repartizați în condițiile prevederilor art. 18 lit. b), prin
        transmiterea unui e-mail, potrivit prevederilor dispoziției anuale a
        șefului Direcției generale management resurse umane, menționată la art.
        4 alin. (1). <br />
        (3) În situația în care există candidați care nu confirmă locul ocupat
        în prima sesiune de admitere, locurile rămase libere, în urma renunțării
        acestora, se adaugă pentru sesiunea a doua de admitere.
        <br />
        (4) După finalizarea admiterii, în termen de până la 3 zile lucrătoare
        de la afișarea rezultatelor finale, școlile militare de maiștri militari
        și subofițeri transmit la Direcția generală management resurse umane și
        la Direcția instruire și doctrină din Statul Major al Apărării situația
        cu locurile rămase neocupate
      </p>
    </div>
  );
};

export default InfoAdmitere;
