import { Grid } from "@material-ui/core";
import React from "react";

const activitati = () => {
  return (
    <div className="info">
      <Grid container item xs={12}>
        <h1 className="mainTitle">ACTIVITĂȚI</h1>
      </Grid>
      <Grid xs={12}>
        <p></p>
      </Grid>
      <Grid container xs={12} justify="center">
        <Grid container item xs={12} justify="center">
          <div className="linkContainer2">
            <a
              href="https://www.facebook.com/100086615554087/posts/545286751701839/?mibextid=rS40aB7S9Ucbxw6v"
              target="_blank"
            >
              Sesiunea de Comunicări științifice ediția a XIV-a de la Școala
              Militară de Maiștri Militari a Forțelor Navale,, Amiral Ion
              Murgescu ".{" "}
            </a>
          </div>
        </Grid>
      </Grid>
      <Grid xs={12}>
        <p></p>
      </Grid>
      <Grid container xs={12} justify="center">
        <Grid container item xs={12} justify="center">
          <div className="linkContainer2">
            <a
              href="https://www.facebook.com/100064448569371/posts/910577757767168/?mibextid=rS40aB7S9Ucbxw6v"
              target="_blank"
            >
              Şcoala Militară de Maiștri Militari și Subofițeri de Logistică
              „Gheorghe Lazăr” a primit o nouă serie de cursanți. <span />
            </a>
          </div>
        </Grid>
      </Grid>
      <Grid xs={12}>
        <p></p>
      </Grid>
      <Grid container xs={12} justify="center">
        <Grid container item xs={12} justify="center">
          <div className="linkContainer2">
            <a
              href="https://www.facebook.com/SMMMSL/posts/pfbid02k8vJtJ9bnG1WnE6mbqmfkNGuKnajRFCjDJgKjXkrnW4bofXUtEz6kSfpmmkiSLpbl"
              target="_blank"
            >
              Sesiuni de comunicări științifice a elevilor organizată de,,
              Școala Militara de Maiștri Militari și Subofițeri pentru
              Comunicații, Tehnologia Informației și Apărare Cibernetica" Sibiu.
            </a>
          </div>
        </Grid>
      </Grid>
      <Grid xs={12}>
        <p></p>
      </Grid>
      <Grid container xs={12} justify="center">
        <Grid container item xs={12} justify="center">
          <div className="linkContainer2">
            <a
              href="https://www.facebook.com/SMMMSL/posts/pfbid02rzt7G43Sbi5J8PNScFssbbWcr6msq6g412kVZnXBVZdABZXP8oY9jP1VTqwURCaJl"
              target="_blank"
            >
              Ziua Eroilor la liceul Tehnologic ,, Grigore Cerchez"
            </a>
          </div>
        </Grid>
      </Grid>
      <Grid xs={12}>
        <p></p>
      </Grid>
      <Grid container xs={12} justify="center">
        <Grid container item xs={12} justify="center">
          <div className="linkContainer2">
            <a
              href="https://www.facebook.com/SMMMSL/posts/pfbid02mZUxHWjDdFcjVpuBGuwUTvg7gPN5po4dwEqL2inzQ8saT4zSHR7YqG38AMmSZM3Xl"
              target="_blank"
               
            >
              Participarea elevilor la Concursul Regional Interdisciplinar,, Să descoperim împreună România autentică" 
            </a>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default activitati;
