import React from 'react'
import "./sefPromotie.css"

function sefPromotie() {
    return (
        <div className="comandanti-wrapper">

        <div className="sef">
            <h3>Șefi de promoție</h3>

          <div className="row-wrapper">
            <div className="row-hidden">
            <span className="row1-name">Nume şi prenume</span>
            <span className="row1-period">Promoția</span>
            </div>


            <div className="row-sef">
            <span className="row2-name">Sg. Antoaneta Georgiana Vamanu</span>
            <span className="row2-period">Promoția 2022</span>
            </div>
         </div>
         </div>   
        </div>
    )
}

export default sefPromotie
