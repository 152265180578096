import { Grid } from "@material-ui/core";
import React from "react";
import "./elevi.css";
import drepturile from "./Drepturile_elevilor.pdf";
import Obligatiile_elevilor from "./Obligatiile_elevilor.pdf"
import Recompense from "./Recompense.pdf"
import Evaluare from "./Evaluarea_rezultatelor.pdf"

const Elevi = () => {
  return (
    <div className="info">
      <Grid container item xs={12} md={12}>
        {" "}
        <a href={drepturile} target="_blank">
          <p className="elevi">
            <i class="fa fa-thumb-tack" aria-hidden="true"></i>Drepturile
            elevilor
          </p>
        </a>
      </Grid>
      <Grid container item xs={12} md={12}>
        {" "}
        <a href={Obligatiile_elevilor} target="_blank">
          <p className="elevi">
            <i class="fa fa-thumb-tack" aria-hidden="true"></i>Obligațiile elevilor
          </p>
        </a>
      </Grid>
      <Grid container item xs={12} md={12}>
        {" "}
        <a href={Recompense} target="_blank">
          <p className="elevi">
            <i class="fa fa-thumb-tack" aria-hidden="true"></i>Recompense/Sancțiuni
          </p>
        </a>
      </Grid>
      <Grid container item xs={12} md={12}>
        {" "}
        <a href={Evaluare} target="_blank">
          <p className="elevi">
            <i class="fa fa-thumb-tack" aria-hidden="true"></i>Evaluarea rezultatelor
          </p>
        </a>
      </Grid>
    </div>
  );
};

export default Elevi;
