import { Grid } from "@material-ui/core";
import React from "react";
import listaPosturiVacante from "./listaPosturiVacante.pdf";
import calendar from "./calendar.pdf";
import anuntPosturiDidactice from "./anuntPosturiDidactice.pdf";
import anunt08052023 from './anunt08052023.pdf';
import anuntAvizMapn from './anuntAvizMapn.pdf';

const Angajare = () => {
  return (
    <div className="info">
      <Grid item xs={12} md={12} alignContent="center" justifyContent="center" alignItems="center" >
        {" "}
        <p className="details">
          {/* Concursul se va desfășura conform prevederilor Metodologiei de
          organizare și desfășurare a concursurilor de ocupare a posturilor
          didactice/catedrelor care se vacantează pe parcursul anului școlar în
          unitățile de stat, aprobată prin OMEN nr. 4959/2013, cu modificările
          și completările ulterioare și a D.G.M.R.U. 2 din 10.01.2020,
          Dispoziție pentru aprobarea "I.M. 3/70", Metodologia-cadru privind
          organizarea și desfășurarea activității de evaluare a personalului
          didactic în vederea acordării avizului Ministarului Apărării Naționale
          pentru ocuparea posturilor didactice/catedrelor vacante/rezervate din
          unitățile de învățământ preuniversitare militar. */}
        </p>
      </Grid>

      {/* <Grid container item xs={12} justify="center">
        <div className="linkContainer1">
          <a className="subTitle" href={listaPosturiVacante} target="_blank">
            Lista posturilor didactice / catedrelor vacante/rezervate rămase
            neocupate la nivelul unității
          </a>
        </div>
      </Grid> */}
      <Grid container item xs={12} justify="center">
        <div className="linkContainer1">
          <a className="subTitle" href={anuntAvizMapn} target="_blank">
            Anunț organizare activitate de evaluare a personalului didactic în
            vederea acordării avizului MApN
          </a>
        </div>
      </Grid>
      <Grid container item xs={12} justify="center">
        <div className="linkContainer1">
          <a className="subTitle" href={calendar} target="_blank">
            Calendarul privind organizarea și desfășurarea activității de
            evaluare a personalului didactic în vederea acordării avizului MApN
            pentru ocuparea posturilor didactice
          </a>
        </div>
      </Grid>
      <Grid container item xs={12} md={12} alignContent="center" justifyContent="center" alignItems="center" >
        {" "}
        <h5> 
          Vezi{" "}
          <a
            href="http://titularizare.edu.ro/2023/generated/files/j/index.html#B"
            target="_blank"
          >
            aici
          </a>{" "}
          mai multe detalii !
        </h5>
      </Grid>
    </div>
  );
};

export default Angajare;
