import React, { useCallback, useState } from "react";
import "./absolventi.css";
import ImageViewer from "react-simple-image-viewer";
import image1 from "./DSC_3388.JPG";
import image2 from "./DSC_3401.JPG";
import image3 from "./DSC_3420.JPG";
import image4 from "./DSC_3430.JPG";
import image5 from "./DSC_3441.JPG";
import image6 from "./DSC_3576.JPG";
import image7 from "./DSC_3578.JPG";
import image8 from "./DSC_3640.JPG";
import image9 from "./DSC_3680.JPG";
import image10 from "./DSC_3712.JPG";
import image11 from "./DSC_3723.JPG";
import image12 from "./DSC_3729.JPG";
import image13 from "./DSC_3733.JPG";
import image14 from "./DSC_3739.JPG";

function Absolventi() {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    // image8,
    image9,
    image10,
    image11,
    image12,
    image13,
    image14,
  ];

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <div className="title">
      <h2 style={{textAlign: 'center'}}>Imagini de la Festivitatea de absolvire a subofiţerilor</h2>

      {images.map((src, index) => (
        <img
          src={src}
          onClick={() => openImageViewer(index)}
          width="300"
          key={index}
          className="image-viewer"
          alt=""
        />
      ))}

      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      )}
    </div>
  );
}

export default Absolventi;
