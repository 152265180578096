import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";
import "./navbar.css";
import stema from "./stema.jpg";

const navbar = () => {
  return (
    <Navbar className="navbar">
      <div className="right-navbar"></div>
      <Navbar.Brand as={Link} to="/">
        <img src={stema} alt="stema" className="stema"></img>
      </Navbar.Brand>
      <div className="left-navbar--desktop">
        <span className="school-title">
          ŞCOALA MILITARĂ DE MAIŞTRI MILITARI ŞI SUBOFIŢERI DE LOGISTICĂ
        </span>
        <span className="school-title-under">„GHEORGHE LAZĂR”</span>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Nav className="ml-auto">
          <Nav.Link as={Link} to="/" className="navbar-button">
            Home
          </Nav.Link>
          <NavDropdown
            title="Despre noi"
            id="collasible-nav-dropdown"
            className="navbar-button"
          >
            <NavDropdown.Item href="/misiune" className="navbar-button--option">
              <p className="navbar-button--option--label">Misiune</p>
            </NavDropdown.Item>
            <NavDropdown.Divider className="navbar-divider" />
            <NavDropdown.Item href="/viziune" className="navbar-button--option">
              Visiune
            </NavDropdown.Item>
            <NavDropdown.Divider className="navbar-divider" />
            <NavDropdown.Item href="/istoric" className="navbar-button--option">
              Istoric
            </NavDropdown.Item>
            <NavDropdown.Divider className="navbar-divider" />
            <NavDropdown.Item
              href="/conducere"
              className="navbar-button--option"
            >
              Conducere
            </NavDropdown.Item>
            <NavDropdown.Divider className="navbar-divider" />
            <NavDropdown.Item
              href="/heraldica"
              className="navbar-button--option"
            >
              Heraldica
            </NavDropdown.Item>
            <NavDropdown.Divider className="navbar-divider" />
            <NavDropdown.Item
              href="/organizare"
              className="navbar-button--option"
            >
              Organizare
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown
            title="Invatamant"
            id="collasible-nav-dropdown"
            className="navbar-button"
          >
            <NavDropdown.Item href="/activitati" className="navbar-button--option">
            ACTIVITĂȚI
            </NavDropdown.Item> 
            <NavDropdown.Item href="/elevi" className="navbar-button--option">
              Elevi
            </NavDropdown.Item> 
            <NavDropdown.Item href="/" className="navbar-button--option">
              Procesul de invatamant
            </NavDropdown.Item>
            <NavDropdown.Divider className="navbar-divider" />
            <NavDropdown.Item href="/" className="navbar-button--option">
              Echivalarea studiilor in viata civila
            </NavDropdown.Item>
            <NavDropdown.Divider className="navbar-divider" />
            <NavDropdown.Item href="/" className="navbar-button--option">
              Formare continua
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown
            title="Admitere"
            id="collasible-nav-dropdown"
            className="navbar-button"
          >
            <NavDropdown.Item
              href="/InfoAdmitere"
              className="navbar-button--option"
            >
              Informatii admitere
            </NavDropdown.Item>
            <NavDropdown.Divider className="navbar-divider" />
            <NavDropdown.Item
              href="/subiecte"
              className="navbar-button--option"
            >
              Subiecte admitere
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown
            title="Trepte in timp"
            id="collasible-nav-dropdown"
            className="navbar-button"
          >
            <NavDropdown.Item
              href="/comandanti"
              className="navbar-button--option"
            >
              Comandanti
            </NavDropdown.Item>
            <NavDropdown.Divider className="navbar-divider" />
            <NavDropdown.Item
              href="/promotie"
              className="navbar-button--option"
            >
              Sefi de promotie
            </NavDropdown.Item>
            <NavDropdown.Divider className="navbar-divider" />
            <NavDropdown.Item
              href="/absolventi"
              className="navbar-button--option"
            >
              Absolventi
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown
            title="Informatii de interes public"
            id="collasible-nav-dropdown"
            className="navbar-button"
          >
            <NavDropdown.Item href="/contact" className="navbar-button--option">
              Solicitare informatii
            </NavDropdown.Item>
            <NavDropdown.Divider className="navbar-divider" />
            <NavDropdown.Item href="/" className="navbar-button--option">
              Declaratii de avere
            </NavDropdown.Item>
            <NavDropdown.Divider className="navbar-divider" />
            <NavDropdown.Item
              href="/anunturi"
              className="navbar-button--option"
            >
              Cariera / Anunturi
            </NavDropdown.Item>
            <NavDropdown.Divider className="navbar-divider" />
            <NavDropdown.Item href="/angajare" className="navbar-button--option">
              Angajare personal didactic
            </NavDropdown.Item>
            <NavDropdown.Divider className="navbar-divider" />
            <NavDropdown.Item
              href="/contact"
              className="navbar-button--option"
            >
              Informatii de interes public
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link as={Link} to="/contact" className="navbar-button">
            Contact
          </Nav.Link>
        </Nav>
      </div>

      <div className="left-navbar--mobile">
        <span className="school-title">
          ŞCOALA MILITARĂ DE MAIŞTRI MILITARI ŞI SUBOFIŢERI DE LOGISTICĂ
        </span>
        <span className="school-title-under">„GHEORGHE LAZĂR”</span>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Nav>
          <NavDropdown className="menu-button" title="Meniu">
            <Nav.Link as={Link} to="/" className="navbar-button">
              Home
            </Nav.Link>
            <NavDropdown
              title="Despre noi"
              id="collasible-nav-dropdown"
              className="navbar-button"
            >
              <NavDropdown.Item
                href="/misiune"
                className="navbar-button--option"
              >
                <p className="navbar-button--option--label">Misiune</p>
              </NavDropdown.Item>
              <NavDropdown.Divider className="navbar-divider" />
              <NavDropdown.Item
                href="/viziune"
                className="navbar-button--option"
              >
                Visiune
              </NavDropdown.Item>
              <NavDropdown.Divider className="navbar-divider" />
              <NavDropdown.Item
                href="/istoric"
                className="navbar-button--option"
              >
                Istoric
              </NavDropdown.Item>
              <NavDropdown.Divider className="navbar-divider" />
              <NavDropdown.Item
                href="/conducere"
                className="navbar-button--option"
              >
                Conducere
              </NavDropdown.Item>
              <NavDropdown.Divider className="navbar-divider" />
              <NavDropdown.Item
                href="/heraldica"
                className="navbar-button--option"
              >
                Heraldica
              </NavDropdown.Item>
              <NavDropdown.Divider className="navbar-divider" />
              <NavDropdown.Item
                href="/organizare"
                className="navbar-button--option"
              >
                Organizare
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              title="Invatamant"
              id="collasible-nav-dropdown"
              className="navbar-button"
            >
              <NavDropdown.Item href="/" className="navbar-button--option">
                Procesul de invatamant
              </NavDropdown.Item>
              <NavDropdown.Divider className="navbar-divider" />
              <NavDropdown.Item href="/" className="navbar-button--option">
                Echivalarea studiilor in viata civila
              </NavDropdown.Item>
              <NavDropdown.Divider className="navbar-divider" />
              <NavDropdown.Item href="/" className="navbar-button--option">
                Formare continua
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              title="Admitere"
              id="collasible-nav-dropdown"
              className="navbar-button"
            >
              <NavDropdown.Item
                href="/InfoAdmitere"
                className="navbar-button--option"
              >
                Informatii admitere
              </NavDropdown.Item>
              <NavDropdown.Divider className="navbar-divider" />
              <NavDropdown.Item
                href="/subiecte"
                className="navbar-button--option"
              >
                Subiecte admitere
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              title="Trepte in timp"
              id="collasible-nav-dropdown"
              className="navbar-button"
            >
              <NavDropdown.Item
                href="/comandanti"
                className="navbar-button--option"
              >
                Comandanti
              </NavDropdown.Item>
              <NavDropdown.Divider className="navbar-divider" />
              <NavDropdown.Item
                href="/promotie"
                className="navbar-button--option"
              >
                Sefi de promotie
              </NavDropdown.Item>
              <NavDropdown.Divider className="navbar-divider" />
              <NavDropdown.Item
                href="/absolventi"
                className="navbar-button--option"
              >
                Absolventi
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              title="Informatii de interes public"
              id="collasible-nav-dropdown"
              className="navbar-button"
            >
              <NavDropdown.Item
                href="/contact"
                className="navbar-button--option"
              >
                Solicitare informatii
              </NavDropdown.Item>
              <NavDropdown.Divider className="navbar-divider" />
              <NavDropdown.Item href="/" className="navbar-button--option">
                Declaratii de avere
              </NavDropdown.Item>
              <NavDropdown.Divider className="navbar-divider" />
              <NavDropdown.Item
                href="/anunturi"
                className="navbar-button--option"
              > 
                Cariera / Anunturi
              </NavDropdown.Item>
              <NavDropdown.Divider className="navbar-divider" />
              <NavDropdown.Item
                href="/angajare"
                className="navbar-button--option"
              >
                Angajare personal didactic
              </NavDropdown.Item>
              <NavDropdown.Divider className="navbar-divider" />
              <NavDropdown.Item
                href="/contact"
                className="navbar-button--option"
              >
                Informatii de interes public
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={Link} to="/contact" className="navbar-button">
              Contact
            </Nav.Link>
          </NavDropdown>
        </Nav>
      </div>
    </Navbar>
  );
};

export default navbar;
