import React from 'react';
import { Route, Switch }  from 'react-router-dom';
import Home from './components/home/Home';
import Services from './components/services/';
import Contact from './components/contact/Contact';
import NotFound from './components/404';
import Misiune from './components/despreNoi/misiune/misiune';
import Conducere from './components/despreNoi/conducere/conducere';
import Heraldica from './components/despreNoi/heraldica/heraldica';
import Comandanti from './components/trepteInTimp/comandanti/Comandanti';
import Viziune from './components/despreNoi/viziune/viziune';
import Istoric from './components/despreNoi/istoric/istoric';
import InfoAdmitere from './components/admitere/informatiiAdmitere/infoAdmitere';
import Organizare from './components/despreNoi/organizare/Organizare';
import Anunturi from './components/anunturi/Anunturi';
import Subiecte from './components/admitere/subiecte';
import Absolventi from './components/trepteInTimp/comandanti/absolventi/absolventi';
import sefPromotie from './components/trepteInTimp/comandanti/sefPromotie/sefPromotie';
import Angajare from './components/angajare/angajare';
import Elevi from './components/elevi/elevi';
import activitati from './components/activitati/activitati';

export default () => (
    <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/services" component={Services} />
        <Route path="/contact" component={Contact} />
        <Route path="/misiune" component={Misiune} />
        <Route path="/conducere" component={Conducere} />
        <Route path="/heraldica" component={Heraldica} />
        <Route path="/comandanti" component={Comandanti} />
        <Route path="/viziune" component={Viziune} />
        <Route path="/istoric" component={Istoric} />
        <Route path="/InfoAdmitere" component={InfoAdmitere} />
        <Route path="/organizare" component={Organizare} />
        <Route path="/anunturi" component={Anunturi} />
        <Route path="/subiecte" component={Subiecte} />
        <Route path="/absolventi" component={Absolventi} />
        <Route path="/promotie" component={sefPromotie} />
        <Route path="/angajare" component={Angajare} />
        <Route path="/elevi" component={Elevi} />
        <Route path="/activitati" component={activitati} />
        <Route component={NotFound} />
    </Switch>
);
